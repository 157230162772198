
import "./index.css"
import React from 'react';
import { Button, Result } from 'antd';
const PageNotFound = () => (
    <div className='flex justify-center ' id="page404">

        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={() => { window.open("/", "_self") }} className='bg-[#3800ff] m-2 ml-9 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg' >Back Home</Button>}
        />
    </div>
);

export default PageNotFound;