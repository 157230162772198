import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Line } from '@ant-design/plots';

const MultiLineChart = ({ data }) => {

  const config = {
    data,
    xField: 'date',
    yField: 'amount',
    seriesField: 'state',
    lineStyle: {
      lineWidth: 4
    },

    yAxis: {
      label: {
        // formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
        formatter: (v) => `$${(v)}`,

        style: {
          fill: 'white'
        }
      },
    },

    xAxis: {
      label: {
        style: {
          fill: 'white'
        }
      }
    },

    colorField: 'state',
    color: (obj) => {
      if (obj.state === 'borrow') {
        return '#1979C9'
      }
      else if (obj.state === "colletral") {
        return '#00FF00'
      }
      else {

        return '#D62A0D'
      }
    },

    legend: {
      position: 'top',
    },
    smooth: true,

    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  return <Line {...config} />;
};

export default MultiLineChart;
