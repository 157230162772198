import { Button } from "antd";
// import { useEffect, useState } from "react";
// import Helmet from "react-helmet";

export default function SignUp() {






    // useEffect(() => {
    //     const modifyDOM = () => {
    //         const inputElements = document.querySelectorAll('.gw-w-full.gw-rounded.gw-text-sm.gw-transition.gw-duration-300');
    //         const noneField = document.querySelectorAll('.gw-mt-4.gw-text-center.gw-text-sm');
    //         const noneField2 = document.querySelectorAll('.gw-flex.gw-flex-row.gw-justify-center');
    //         const questionText = document.querySelectorAll('.gw-text-sm.gw-font-semibold.gw-text-left');

    //         if (questionText.length > 0) {
    //             questionText[0].innerHTML = 'Wallet';
    //         }
    //         if (noneField.length > 0) {
    //             noneField[0].innerHTML = null;
    //         }
    //         if (noneField2.length > 1) {
    //             noneField2[1].innerHTML = null;
    //         }
    //         if (inputElements.length > 0) {
    //             const emailInput = inputElements[0].querySelector('input[type="email"]');
    //             if (emailInput && emailInput.placeholder) {
    //                 emailInput.placeholder = 'team@lendvest.io';
    //             }
    //         }
    //     };

    //     modifyDOM();
    // }, []);

    // useEffect(() => {

    //     if (flagRender == false) {

    //         var input = document.getElementsByClassName('gw-w-full gw-rounded gw-text-sm gw-transition gw-duration-300');
    //         console.log("aaaaaaaaaaaa", input)

    //         let noneField = document.getElementsByClassName(' gw-mt-4  gw-text-center  gw-text-sm ');
    //         let noneField2 = document.getElementsByClassName('  gw-flex   gw-flex-row   gw-justify-center   ');
    //         let questionText = document.getElementsByClassName('  gw-text-sm   gw-font-semibold  gw-text-left  ');
    //         if (questionText && questionText.length > 0 && questionText[0].innerHTML) {
    //             questionText[0].innerHTML = 'Wallet'
    //         }
    //         if (noneField && noneField.length > 0 && noneField[0].innerHTML) {
    //             noneField[0].innerHTML = null
    //         }
    //         if (noneField2 && noneField2.length > 0 && noneField2[1].innerHTML) {
    //             noneField2[1].innerHTML = null
    //         }
    //         if (input && input.email && input?.email?.placeholder) {
    //             input.email.placeholder = 'team@lendvest.io'
    //         }

    //         setInputWhiteList({ ...input });

    //     }


    //     if (inputWhiteList?.email?.placeholder == 'team@lendvest.io') {

    //         setFlagRender(true)

    //     }
    // }, [flagRender, !!inputWhiteList])

    // <div className="flex flex-col justify-center" id="getWaitlistContainer" data-waitlist_id="9479" data-widget_type="WIDGET_1"></div>
    // {/* <h2 className="text-center text-xl font-medium text-white">Become an On-Chain Alpha Tester</h2> */}
    // <div>
    //     {/* <div id="getWaitlistContainer" data-waitlist_id="9479" data-widget_type="WIDGET_1"></div> */}
    //     <Helmet>
    //         {/* <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css" />
    //         <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script> */}

    //         <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css" />
    //         <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>

    //     </Helmet>
    // </div>



    return (
        // <div className="flex justify-center flex-col-reverse mt-4">
        <div>

            <div className="flex flex-col justify-center" id="getWaitlistContainer" data-waitlist_id="9479" data-widget_type="WIDGET_1"></div>
            <h2 className="text-center text-xl font-medium text-white">Become an On-Chain Alpha Tester</h2>
            <div className="flex justify-center">
                <Button onClick={() => window.open("https://tally.so/r/wgbGX4", "_blank")} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                    Register
                </Button>
                <Button className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg" onClick={() => window.open("https://t.me/+raGt1GcZ9Ug0NGEx", "_blank")}>Telegram</Button>
            </div>

        </div>
    );
}


