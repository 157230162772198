import logo from "./logo.svg";
import "./App.css";
import CredApp from "./Pages/CredApp";
import OnChain from "./Pages/OnChain";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Reports from "./Pages/Reports";
import '@rainbow-me/rainbowkit/styles.css';

import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum, sepolia } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import PageNotFound from "./Pages/PageNotFound";


const { chains, provider } = configureChains(
  [sepolia],
  [
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  projectId: 'YOUR_PROJECT_ID',
  chains
});

const wagmiClient = createClient({
  // autoConnect: true,
  connectors,
  provider
})

function App() {
  return (
    <div className="bg-gradient-to-r from-[#08091b] to-[#08091b]">

      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Reports />} />
              <Route path="/on-chain" element={<OnChain />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>

        </RainbowKitProvider>
      </WagmiConfig>
    </div>

  );
}

export default App;
