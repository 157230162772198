import { Table, Tooltip } from 'antd';
import "./index.css"
const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};

const Tables = ({ data, columns }) => {

  const stripedRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return 'tr-even';
    }
    else if (index % 2 === 1) {
      return 'tr-odd';
    }
  };

  const handleRowClick = (record) => {
    window.open(`https://etherscan.io/tx/${record["transaction_hash"]}`, '_blank', 'noopener,noreferrer');
  }

  function CustomRow(props) {

    return (
      <Tooltip title={"Click to see Tx"}>
        <tr {...props} />
      </Tooltip>
    );
  }



  return (
    <div className={'rounded-tr-2xl rounded-br-2xl p-5'} style={{ background: 'unset' }}>
      <Table columns={columns}
        components={{
          body: {
            row: CustomRow
          }
        }}

        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleRowClick(record),

          };
        }}
        dataSource={data} onChange={onChange} rowClassName={stripedRowClassName} />

    </div>
  )
}
export default Tables;