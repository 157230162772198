import React, { useRef, useState } from "react";
import { Layout, Menu, Input, Button, Dropdown, Space, Select, Divider, Tooltip } from "antd";
import "./index.css"
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { addresses } from "../../constants/addresses";
import { useLocation, useNavigate } from 'react-router-dom';



function PageHeader({ setWallet, wallet }) {


  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  console.log(pathname)


  const selectWallet = () => {

    const index = Math.floor(Math.random() * addresses.length) % addresses.length;
    setWallet(addresses[index])
  };








  return (


    <nav className=" px-2 sm:px-4 py-2.5 dark:bg-gray-900  w-full z-20 top-0  left-0 ">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <a href="#" className="flex items-center">
          <span className="text-white self-center text-xl font-semibold whitespace-nowrap dark:text-white"><img src="/logo.png" /></span>
        </a>
        <div className="flex md:order-2">

          {/* <Input style={{ color: '#ffffff' }} className="mr-5 h-12 c-w-i placeholder-white" placeholder="0x..." value={!!wallet ? <a href={"https://etherscan.io/address/${'0x47c535606EB5FA62007674c2A68868AB1880F87E"}>{wallet}</a> : ""} disabled={true} /> */}
          {pathname !== "/on-chain" ?
            <>
              <Button onClick={() => navigate('/on-chain')} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                On-Chain App
              </Button>
              <Button onClick={() => { !!wallet && window.open(`https://etherscan.io/address/${wallet}`, '_blank') }} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                {!!wallet ? wallet.slice(0, 10) + "..." : "0xabcdefgh..."}
              </Button>
              <Button onClick={selectWallet} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                Random Wallet
              </Button>
            </>
            :
            <>
              <Button onClick={() => navigate('/')} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                Off-Chain App
              </Button>
              <Button onClick={() => { window.open("https://drive.google.com/file/d/1Nskrj92uTK5DJ9IiIynJuMkFVB-ce5nx/view?usp=sharing", "_blank") }} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                <Tooltip title="How to use this dApp">
                  Directions
                </Tooltip>
              </Button>
              <Button disabled={!wallet} onClick={() => { !!wallet && window.open(`https://etherscan.io/address/${wallet}`, '_blank') }} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                <Tooltip title="View on Etherscan">
                  {!!wallet ? wallet.slice(0, 10) + "..." : "0xabcdefgh..."}
                </Tooltip>
              </Button>
              <ConnectButton showBalance={false} chainStatus={"none"} accountStatus={{ smallScreen: "full" }} />
            </>

          }
        </div>
        <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">

        </div>
      </div>
    </nav >



  );
}
export default PageHeader;
