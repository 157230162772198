import React from 'react';
import { Select } from 'antd';
import { addresses } from '../../constants/addresses';


// const onSearch = (value) => {
//     console.log('search:', value);
// };

// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
const SearchSelect = ({ setWallet, disabled }) => (
    <Select
        showSearch
        placeholder="0x0000000000000000000000000000000000000000"
        optionFilterProp="children"
        onChange={(val) => { setWallet(val) }}
        // onSearch={onSearch}
        filterOption={filterOption}
        options={addresses.map((val, i) => { return { label: val, value: val } })}
        disabled={disabled}
        style={{ width: '100%' }}
    />
);
export default SearchSelect;
