import { Button, Card } from "antd";
import { Gauge } from "@ant-design/plots";
import { useContext, useState } from "react";

import { MAX_SCORE, MIN_SCORE } from "./constants";



function GaugeChart({score}) {
  





  

  const config = {
    percent: (Math.round(score) - MIN_SCORE + 50 ) / (MAX_SCORE - MIN_SCORE),
    // percent: 0.6,

    range: {
      color: "l(0) 0:#ff0000 0.5:#FFA500 1:#00FF00",
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontSize: "36px",
          color: "#fff",
        },
        formatter: () => Math.round(score) ,
      },
      content: {
        style: {
          fontSize: "24px",
          lineHeight: "44px",
          color: "#fff",
        },
        formatter: () => "Lendvest",
      },
    },
  };

  return (

      <Gauge {...config}/>

    
  );
}
export default GaugeChart;
