
import { Layout } from "antd";
import { createContext, useState } from "react";
import Header from "../../Components/Header";
// import walletContext from "../WalletContext";


export const walletContext = createContext();

function AppLayout(
  { children, setWallet, wallet }
) {

  const { Content } = Layout;


  return (


    <Layout className=" bg-cover bg-custom from-cyan-800 to-purple-700">

      <Header setWallet={setWallet} wallet={wallet} />

      <Content >

        {children}
      </Content>
      {/* <Footer style={footerStyle}>Footer</Footer> */}

    </Layout>
  );
}

export default AppLayout;