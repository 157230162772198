
import "./index.css"
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { Card, Row, Col, Space } from "antd";
import Loader from "../../Components/Loader";
import Footer from "../../Components/Footer";
import Tables from "../../Components/Tables";
import AppLayout from "../../Components/Layout";
import { BorrowColumns } from "../../constants/tableData";
import GaugeChart from "../../Components/Charts/GaugeChart";
import MultiLineChart from "../../Components/Charts/MultiLineChart";



function Reports() {


  const [score, setScore] = useState(600);
  const [TxCount, SetTxCount] = useState(0)
  const [TxVolume, SetTxVolume] = useState(0)
  const [OutstandingLoans, SetOutstandingLoans] = useState(0)
  const [TotalBalance, SetTotalBalance] = useState(0)
  const [LoanHistory, SetLoanHistory] = useState([])
  const [LoanHistoryTable, SetLoanHistoryTable] = useState([])
  const [wallet, setWallet] = useState("")
  const [loader, setLoader] = useState(false)


  const token_symbol = "ETH"
  const api_key = "ehEb8mBmV2qlAGrLGy1u4t4FeNXsOJoI"
  const api_url = `https://api.polygon.io/v3/trades/X:${token_symbol}-USD?&apiKey=${api_key}`

  function formatNumber(num) {
    if (num < 0) {
      num = num * -1
    }
    if (num >= 1000000) {
      const formatted = (num / 1000000).toFixed(1);
      return formatted.replace(/\.0$/, '') + "M";
    } else if (num >= 1000) {
      const formatted = (num / 1000).toFixed(1);
      return formatted.replace(/\.0$/, '') + "K";
    }
    else {
      return parseFloat(num).toFixed(3);

    }
  }









  const getBalance = async () => {

    const provider = new ethers.providers.JsonRpcProvider("https://blissful-fragrant-hexagon.quiknode.pro/1cf373bd2fe1cf19f290c723f060a8b5b370b54d/")
    const balance = ethers.utils.formatEther(await provider.getBalance(wallet)) || 0


    const response = await fetch(api_url)
    const d = await response.json()


    if (balance) {

      return formatNumber(balance * d.results[0]?.price)
    }

    return 0

  }


  const generateReport = async () => {

    if (wallet) {
      setLoader(true)
      SetLoanHistory([])
      const balance = await getBalance()
      const url = `https://backend.lendvest.io/api/report?address=${wallet}`
      const response = await fetch(url, {
        method: 'GET', // or any other HTTP method
        // mode:'no-cors',
        // headers: {
        //   'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxOTk5NTMzODExLCJpYXQiOjE2ODQxNzM4MTEsImp0aSI6ImU3Y2ViZGZmYzNlNDQwM2Q4NDkyZWIwM2UzOTc0YTAwIiwidXNlcl9pZCI6M30.Z2LICHk9a38uTkRY0mzLEYMSuwJEPco2TobXyIhPnK8',
        //   'Content-Type': 'application/json'
        // }
      })
      const data = await response.json()

      console.log(data)

      data?.loan_history_data.forEach(e => {
        e["loan_amount"] = parseFloat(e["loan_amount"]).toFixed(2) >= 0 ? `$${parseFloat(e["loan_amount"]).toFixed(2)}` : `-$${parseFloat(e["loan_amount"]).toFixed(2) * (-1)}`;

      })

      let i = 0 // to remove warning
      data?.loan_history_data.forEach(e => {
        e["interest_paid"] = `$${parseFloat(e["interest_paid"]).toFixed(2)}`;
        e["key"] = i
        i += 1
      })


      let debtDataGraph = []


      data?.collateral_data.forEach(e => {
        debtDataGraph.push(
          {
            date: e.date,
            state: "colletral",
            amount: e.collateralState
          },

        )
      });



      data?.debt_data.forEach(e => {
        debtDataGraph.push(
          {
            date: e.date,
            state: "borrow",
            amount: e.borrowState
          }

        )
      });


      data?.liquidation_data.forEach(e => {
        debtDataGraph.push(
          {
            date: e.date,
            state: "liquidate",
            amount: e?.liquidationState
          }

        )
      });


      debtDataGraph.sort((a, b) => {
        var c = new Date(a.date);
        var d = new Date(b.date);
        return c - d;

      })



      SetTotalBalance(balance)
      setScore(data?.credit_score)
      SetTxCount(data?.transaction_count)
      SetTxVolume(formatNumber(data?.transaction_volume))
      SetOutstandingLoans(formatNumber(parseFloat((data?.loan_history_data[data?.loan_history_data.length - 1]?.loan_amount).replace("$", "")))) //data?.outstanding_loan
      SetLoanHistoryTable(data?.loan_history_data)
      SetLoanHistory(debtDataGraph)
      setLoader(false)

    }

  }
  useEffect(() => {

    if (wallet !== "") {
      generateReport(wallet)
    }
    else {
      generateReport()
    }

  }, [wallet])



  return (
    <AppLayout setWallet={setWallet} wallet={wallet}>

      {loader && <div className="fixed bg-gray-400/80 w-full h-full top-0 bottom-0 left-0 right-0 flex justify-center items-center">
        <Loader />
      </div>
      }

      <div className="mt-20">
        <Space
          direction="vertical"
          size={50}
          style={{
            display: 'flex',
          }}
        >

          <Row justify="space-around">
            <Col span={4}>
              <Card hoverable >
                <span>

                  <div>

                    <div className="flex justify-center items-center flex-col">

                      <div className="text-5xl" >{TxCount}</div>

                      <div className="mt-2 text-lg">Transaction Count</div>
                    </div>

                  </div>


                </span>
              </Card>
            </Col>

            <Col span={4}>
              <Card hoverable>
                <div className="flex justify-center items-center flex-col">

                  <div className="text-5xl" >${TxVolume}</div>

                  <div className="mt-2 text-lg">Transaction Volume</div>
                </div>



              </Card>
            </Col>


            <Col span={4}>

              <Card hoverable>


                <div className="flex justify-center items-center flex-col">

                  <div className="text-5xl">${OutstandingLoans}</div>

                  <div className="mt-2 text-lg">Outstanding Loans</div>
                </div>


              </Card>
            </Col>



            <Col span={4}>
              <Card hoverable>

                <div className="flex justify-center items-center flex-col">

                  <div className="text-5xl">${TotalBalance}</div>

                  <div className="mt-2 text-lg">Total Balance</div>
                </div>


              </Card>
            </Col>
          </Row>



          <div className="flex  w-full justify-around items-center">
            <div className="w-[55%]">
              <Card hoverable>

                <h2 className="text-center text-xl">Loan History</h2>
                {/* <LineChart  data = {LoanHistory}/> */}
                <MultiLineChart data={LoanHistory} />
              </Card>
            </div>
            <div className="w-[30%] -ml-28">
              <Card hoverable>
                <h2 className="text-center text-xl">
                  Credit Score
                </h2>
                <GaugeChart score={score} />
              </Card>
            </div>
          </div>



          <Row justify="space-around">

            <Col span={20}>


              <Card hoverable>
                <h2 className="text-center text-xl">Loan History</h2>
                <h3 className="text-center text-xl"> ETH: {wallet}</h3>
                <Tables data={LoanHistoryTable} columns={BorrowColumns} />
              </Card>

            </Col>

          </Row>
        </Space>

        <Footer />
      </div>
    </AppLayout>
  );
}

export default Reports;
