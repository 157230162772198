import { react } from "react";
import SignUp from "../SignUp";
import { Button } from "antd";


const Footer = () => {
    return (
        <div className="p-2">
            <SignUp />
        </div>
    )
}

export default Footer;